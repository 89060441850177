import React, { useEffect } from 'react'
import styled from 'styled-components'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import parse from 'html-react-parser'

import Section from '../../../styles/Section'
import Arrow from '../../General/Icons/Arrow'
import GlobalTextStyles from '../../General/Text/GlobalTextStyles'

import { colors, media } from '../../../lib/style-utils'

import RevealOpacity from '../../Animation/RevealOpacity'
import RevealImage from '../../Animation/RevealImage'
import Video from '../../General/Video/Video';
import InstagramEmbed from './InstagramEmbed';
import YoutubeEmbed from './YoutubeEmbed';

/* SectionExtended */
const SectionExtended = styled(Section)`
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
    `}
`

const CareerDetailBackLink = styled.div`
    padding: 2rem 0 1rem 0;
`

const CareerDetailBackLinkInner = styled.div`
    font-weight: 500;
    color: ${colors.grey};
    transition: all 0.3s ease;
    svg{
        width: 20px;
        position: relative;
        top: -1px;
        display: inline;
        margin-right: 10px;
    }
    svg polyline{
        stroke: ${colors.grey};
        transition: all 0.3s ease;
    }
    a :hover svg polyline{
        stroke: orange;
        transition: all 0.3s ease;
    }
    a{
        font-style: italic;
    }
    a :hover{
        color: orange;
        transition: all 0.3s ease;
    }
`

const MainContainer = styled.div`
    width: 100%;
    position: relative;
`
const TextMain = styled.h1`
    font-weight:800;
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    background: ${colors.gradient2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${media.phablet`
        font-size: 4rem;
        line-height: 4rem;
        padding-bottom: 1rem;
    `};
    ${media.desktop`
        margin-top: 2.7rem;
        font-size: 4.4rem;
        line-height: 4.6rem;
    `};
    ${media.midDesktop`
        font-size: 4.4rem;
        line-height: 4.6rem;
    `};
`

const TextWe = styled.h2`
    text-align: left;
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 800;
    color: ${colors.dark_grey};
    margin-bottom: 0 !important;
    ${media.phablet`
        font-size: 1.4rem;
        line-height: 1.6rem;
    `};
    ${media.desktop`
        margin-bottom: 3rem !important;
        font-size: 2rem;
        line-height: 2.4rem;
    `};
`

const CaseContent = styled.div``

const CaseIntro = styled.div``

const TagsContainer = styled.ul`
    width: 100%;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${colors.dark_grey};
    margin-top: 2rem !important;
    ${media.tablet`
        margin-top: 3rem !important;
    `}
`
const Tags = styled.li`
    margin-bottom: 0.5rem !important;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1rem;
    color: ${colors.dark_grey};
    list-style-type: none;
    ${media.phablet`
        font-size: 0.7rem;
    `};
    ${media.tablet`
        margin-bottom: 1rem;
        font-size: 0.8rem;
    `};
    ${media.midDesktop`
        font-size: 0.8rem;
    `};
    ::marker{display: none !important;}
`

const Banner = styled.img`
    margin: 0rem;
    margin-top: 1.5rem;
    ${media.tablet`
        margin-top: 1rem;
    `}
    ${media.tablet`
        width: 100%;
    `};
`
const SubImage = styled.img`
    width: 100%;
    height: 100%;
    margin: 3rem auto;
`

const CaseDetailContentWrap = styled.div`
    margin-bottom: 3rem;
    ${media.tablet`
        margin-bottom: 6rem;
    `}
`

const CaseQuestion = styled.div`
    margin-top: 1rem;
    ${media.tablet`
        margin-top: -3rem;
    `}
    ${media.desktop`
        margin-top: -6rem;
    `}
    z-index: 9999;
`

const CaseQuestionInner = styled.div`
    background-color: #FFF;

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    margin: 0 auto;
    ${media.phablet`
        width: calc(100% - 40px);
        padding: 2rem 1.5rem;
    `}
    ${media.desktop`
        padding: 3rem 3rem;
        width: 80%;
        max-width: 60rem;
        min-height: 6.1rem;
        margin: 0 auto;
    `}
`
const CaseOnlyimageContainer = styled.div`
    width: 100%;
    max-width: 60rem;
    margin: 1.5rem auto;
    ${media.desktop`
        width: 80%;
        max-width: 60rem;
        margin: 3rem auto;
    `}
`

const CaseYoutubeWrapper = styled.div`
    width: 100%;
    max-width: 60rem;
    margin: 1.5rem auto;
    ${media.desktop`
        width: 80%;
        max-width: 60rem;
    `}
`

const CaseOnlytextInner = styled.div`
    padding-bottom: 0;
    margin: 0 auto;

    ${media.phablet`
        padding: 0 1.5rem 1rem 1.5rem;
        width: calc(100% - 40px);
    `}
    ${media.desktop`
        width: 80%;
        max-width: 60rem;
        margin: 0 auto;
        padding: 0 3rem;
    `}
`

const VideoWrapper = styled.div`
    margin-top: 0;
    ${media.tablet`
        margin-top: 1rem;
    `}
    ${media.desktop`
        margin-top: 3rem;
    `}
`

const CaseDetailPage = (data) => {
        const detail= data.data

        return (
            <MainContainer>
                <SectionExtended>
                    <CareerDetailBackLink>
                        <CareerDetailBackLinkInner>
                            <AniLink
                                to={`/${detail.language}/cases`}
                                cover direction="right" 
                                duration={1.2}
                                bg="orange"
                            >
                                <Arrow/>
                                Cases
                            </AniLink>
                        </CareerDetailBackLinkInner>
                    </CareerDetailBackLink>
                    <RevealOpacity>
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 lg:col-span-9">
                                <TextMain className="col-span-12">
                                    {detail.title}
                                </TextMain>
                                {detail.casesubtitle && 
                                    <TextWe className="col-span-12">
                                        {parse(detail.casesubtitle)}
                                    </TextWe>
                                }
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <TagsContainer>
                                    {
                                    detail.categoriesServices.map((tag, i) => {
                                        if (!tag) return null
                            
                                        return (
                                            <Tags key={i}>{tag.title}</Tags>
                                        )
                                        })
                                    }
                                </TagsContainer>          
                            </div>    
                        </div>
                    </RevealOpacity>
                    <CaseDetailContentWrap>
                        <GlobalTextStyles>
                            <div className="grid grid-cols-12">
                                <div className="col-span-12">
                                    <RevealImage dataOffset="200">
                                        <Banner src={detail.casebanner[0].url}/>
                                    </RevealImage>
                                </div>
                                <CaseQuestion className="col-span-12">
                                    <CaseQuestionInner>
                                        <CaseIntro>
                                            { detail.caseintro && parse(detail.caseintro) }
                                        </CaseIntro>
                                    </CaseQuestionInner>
                                </CaseQuestion>
                            </div>
                            {detail.caseimg1[0] ? 
                                <CaseOnlyimageContainer>
                                    <RevealImage dataOffset="100">
                                        <SubImage src={detail.caseimg1[0].url}/>
                                    </RevealImage>
                                </CaseOnlyimageContainer>
                                : null
                            }                      
                            {detail.casetext1 ? 
                            <CaseOnlytextInner>
                                <CaseContent>
                                    { detail.casetext1 && parse(detail.casetext1 ) }
                                </CaseContent>
                            </CaseOnlytextInner>
                            : null
                            }
                            {detail.caseinsta1[0] ?
                                <RevealImage dataOffset="100">
                                    <div className="grid grid-cols-2">
                                        {
                                            detail.caseinsta1.map(insta => {
                                                return <InstagramEmbed data={insta} />
                                            })
                                        }
                                    </div>
                                </RevealImage>
                            : null}
                            {detail.caseyoutube1 ?
                                <CaseYoutubeWrapper>
                                    <RevealImage dataOffset="100">
                                        <YoutubeEmbed data={detail.caseyoutube1} />
                                    </RevealImage>
                                </CaseYoutubeWrapper>
                            : null}
                            {detail.caseimg2[0] ? 
                                <CaseOnlyimageContainer>
                                    <RevealImage dataOffset="100">
                                        <SubImage src={detail.caseimg2[0].url}/>
                                    </RevealImage>
                                </CaseOnlyimageContainer>
                                : null
                            }                      
                            {detail.casetext2 ? 
                            <CaseOnlytextInner>
                                <CaseContent>
                                    { detail.casetext2 && parse(detail.casetext2 ) }
                                </CaseContent>
                            </CaseOnlytextInner>
                            : null
                            }
                            {detail.caseinsta2[0] ?
                                <RevealImage dataOffset="100">
                                    <div className="grid grid-cols-2">
                                        {
                                            detail.caseinsta2.map((insta, i) => {
                                                return <InstagramEmbed data={insta} key={i}/>
                                            })
                                        }
                                    </div>
                                </RevealImage>
                            : null}
                            {detail.caseyoutube2 ?
                                <CaseYoutubeWrapper>
                                    <RevealImage dataOffset="100">
                                        <YoutubeEmbed data={detail.caseyoutube2} />
                                    </RevealImage>
                                </CaseYoutubeWrapper>
                            : null}
                            {detail.caseimg3[0] ? 
                                <CaseOnlyimageContainer>
                                    <RevealImage dataOffset="100">
                                        <SubImage src={detail.caseimg3[0].url}/>
                                    </RevealImage>
                                </CaseOnlyimageContainer>
                                : null
                            }                      
                            {detail.casetext3 ? 
                                <CaseOnlytextInner>
                                    <CaseContent>
                                        { detail.casetext3 && parse(detail.casetext3 ) }
                                    </CaseContent>
                                </CaseOnlytextInner>
                            : null
                            }
                            {detail.caseinsta3[0] ?
                                <RevealImage dataOffset="100">
                                    <div className="grid grid-cols-2">
                                        {
                                            detail.caseinsta3.map(insta => {
                                                return <InstagramEmbed data={insta} />
                                            })
                                        }
                                    </div>
                                </RevealImage>
                            : null}
                            {detail.caseyoutube3 ?
                                <CaseYoutubeWrapper>
                                    <RevealImage dataOffset="100">
                                        <YoutubeEmbed data={detail.caseyoutube3} />
                                    </RevealImage>
                                </CaseYoutubeWrapper>
                            : null}
                            {detail.caseimg4[0] ? 
                                <CaseOnlyimageContainer>
                                    <RevealImage dataOffset="100">
                                        <SubImage src={detail.caseimg4[0].url}/>
                                    </RevealImage>
                                </CaseOnlyimageContainer>
                                : null
                            } 
                            {detail.casetext4 ? 
                                <CaseOnlytextInner>
                                    <CaseContent>
                                        { detail.casetext3 && parse(detail.casetext4 ) }
                                    </CaseContent>
                                </CaseOnlytextInner>
                            : null
                            }
                            {detail.caseinsta4[0] ?
                                <RevealImage dataOffset="100">
                                    <div className="grid grid-cols-2">
                                        {
                                            detail.caseinsta4.map(insta => {
                                                return <InstagramEmbed data={insta} />
                                            })
                                        }
                                    </div>
                                </RevealImage>
                            : null}
                            {detail.caseyoutube4 ?
                                <CaseYoutubeWrapper>
                                    <RevealImage dataOffset="100">
                                        <YoutubeEmbed data={detail.caseyoutube4} />
                                    </RevealImage>
                                </CaseYoutubeWrapper>
                            : null}
                        </GlobalTextStyles>
                        <VideoWrapper>
                            {detail.videoDesk[0] ? 
                                <Video  data={detail} />
                            : null}
                        </VideoWrapper>
                    </CaseDetailContentWrap>
                </SectionExtended>
            </MainContainer>
        )
}

export default CaseDetailPage