import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { colors,easings } from '../../../lib/style-utils'
import ActTable from '../../../styles/ActTable'
import ActCell from '../../../styles/ActCell'
import PlayButton from '../../General/Icons/PlayButton'

const VideoSection = styled.div`
    width: 100%;
    max-width: 
    height: auto;
    position: relative;
    margin: 0 auto;
    text-align: center;
    video{
        margin: 0 auto !important;
    }
`

const VideoControls = styled.div`
text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const PlayPauseButton = styled.div`
    display: inline-block;
    background: ${colors.gradient};
    padding: 1.2rem;
    border-radius: 1rem 0;
    transition: all ${easings.quick};
    svg{
        width: 30px;
        height: 30px;
        fill: ${colors.white}
    }
`

const Video = (data) => {

    const myVideo = useRef(null)
    const videoController = useRef(null)

    // Get all data- set VideoSrc - Gat Video url's
    const videoData = data.data
    let videoSrc
    const videoDesktUrl = videoData.videoDesk[0].url
    const videoPhoneUrl = videoData.videoSmartphone[0].url
    
    // Set video source depending on screen width
    const getVideoSrc = width => {
        if (width >= 1080){
            videoSrc = videoDesktUrl
        } else {
            videoSrc = videoPhoneUrl
        }
    };
    if (typeof window !== `undefined`) {
        const src = getVideoSrc(window.innerWidth);
    }

    // set variables for video controls
    // const playButton = document.querySelector("#play_button");
    // const pauseButton = document.querySelector("#pause_button");

    // set State
    const [ toggled , toggleState ] = useState(true)

    // toggle state and handle play/pause

    const togglePlayPause = () => {

        // const myVideo = document.getElementById("myVideo");
        // const videoController = document.getElementById('video_controller');
        
        // toggle state
        toggleState(!toggled);

        // handle play or pause for video
        if( toggled ) {
            myVideo.current.play();
            videoController.current.style.opacity = '0';
        } else {
            myVideo.current.pause();
            videoController.current.style.opacity = '1';
        }
    }

    return(
        <VideoSection>

            <video playsInline src={videoSrc} id="myVideo" ref={myVideo} />

            <VideoControls onClick={togglePlayPause}>
                <ActTable>
                    <ActCell>
                        <PlayPauseButton id="video_controller" ref={videoController} className="shadow-sm">
                            { toggled ? <PlayButton/> : <PlayButton/> }
                        </PlayPauseButton>
                    </ActCell>
                </ActTable>
            </VideoControls>
        </VideoSection>
    )
}

export default Video