import React from 'react'

const PlayButton = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.07 27.79">
            <polygon className="st0" points="23.07,13.89 11.78,20.41 0.5,26.92 0.5,13.89 0.5,0.87 11.78,7.38 "/>            
        </svg>
    )
}

export default PlayButton