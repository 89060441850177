import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import HeaderSection from '../components/Case/CaseDetail/CaseDetailPage'
import SEO from "../components/seo"

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`
const Case = data => {

    // Collect data
    const detail = data.data.caseData

    // SEO
    let seo = {
      title: data.data.caseData.seo.title,
      description: data.data.caseData.seo.description,
      lang: data.data.caseData.language,
      image: data.data.caseData.seo.social.facebook.image && data.data.caseData.seo.social.facebook.image.url,
      imageTwitter: data.data.caseData.seo.social.twitter.image && data.data.caseData.seo.social.twitter.image.url,
      robots: data.data.caseData.seo.advanced.robots && data.data.caseData.seo.advanced.robots
    }

    // 1. Language switcher - Create slugs object for language switcher
    const slugs = {
      slugNl: '',
      slugEn: '',
    }
    
    // 2. Language switcher - Build slugs depending on language
    if (data.data.caseData.language == "en"){
      slugs.slugNl = 'cases/'+data.data.caseTranslation.slug
      slugs.slugEn = 'cases/'+data.data.caseData.slug
    } else {
      slugs.slugNl = 'cases/'+data.data.caseData.slug
      slugs.slugEn = 'cases/'+data.data.caseTranslation.slug
    }

    console.log(slugs);

    return(
        <Layout form={true} data={data.data.site} location={data.location} slugs={slugs}>
          <SEO {...seo}/>
        <Container>
          <HeaderSection data={detail} />
        </Container>
      </Layout>
    )
}
  
export default Case

export const query = graphql`
query ($id: ID!, $siteId:Int, $siteTransId:Int) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    caseData:craftCaseCaseEntry(remoteId: {eq: $id}, siteId: {eq: $siteId}) {
        id
        language
        uri
        slug
        title
        casesubtitle
        caseintro
        casetext1
        casetext2
        casetext3
        casetext4
        caseinsta1{
          ... on craft_caseinsta1_caseinsta1_BlockType {
            id
            embedUrl
          }
        }
        caseinsta2{
          ... on craft_caseinsta2_caseinsta2_BlockType {
            id
            embedUrl
          }
        }
        caseinsta3{
          ... on craft_caseinsta3_caseinsta3_BlockType {
            id
            embedUrl
          }
        }
        caseinsta4{
          ... on craft_caseinsta4_caseinsta4_BlockType {
            id
            embedUrl
          }
        }
        caseyoutube1
        caseyoutube2
        caseyoutube3
        caseyoutube4
        seo {
          title
          description
          social {
            facebook {
              image {
                url
              }
            }
            twitter {
              image {
                url
              }
            }
          }
          advanced{
            robots
          }
        }
        categoriesServices {
          title
        }
        casetext1
        casecardimg {
          url
        }
        casebanner {
          url
        }
        caseimg1 {
          url
        }
        caseimg2 {
          url
        }
        caseimg3 {
          url
        }
        caseimg4 {
          url
        }
        videoDesk{
          url
        }
        videoSmartphone{
          url
        }
    }
    caseTranslation:craftCaseCaseEntry(remoteId: {eq: $id}, siteId: {eq: $siteTransId}) {
      slug
    }
}
`
